<template>
  <CCol xs="12" xl="9">
    <CCard>
      <!-- <CCardHeader><h5 class="card-title">Documentación</h5></CCardHeader> -->
      <CCardBody>
        <CDataTable
          :items="items"
          :fields="fields"
          hover
          :header="false"
        >
        <template #download="{item}">
        <td>
          <CButton 
            color="success"
            @click="downloadFile(item.download)"
          >
            DESCARGAR
           <PdfIcon />
          </CButton>
        </td>
      </template>
      </CDataTable>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import PdfIcon from "@/components/atoms/PdfIcon";

const requirePDFs = require.context('@/assets/documents', false, /\.pdf$/);

export default {
  name: "Documentation",
  components: {
    PdfIcon,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
        },
        {
          key: "download",
        },
      ],
      items: [
        {
          name: "Requerimientos generales ART",
          download: "20210503_Requerimientos-Nuevo-ART.pdf",
        },
        {
          name: "Cargas automáticas y cálculos de compras",
          download: "20210628-Invoice_Carga-y-Calculo_V4-New-ART.pdf",
        },
        {
          name: "Seguimiento de Recall Campaigns",
          download: "20220124-Seguimiento-Recall.pdf",
        },
        {
          name: "Informe de Accesorios",
          download: "20220603-Informe-Accesorios_Nibsa-V2.pdf",
        },
        {
          name: "Informe de DC",
          download: "20220929-Informe-DC-Nibsa.pdf",
        },
        {
          name: "Informe de estimación de incentivos concesión",
          download: "20230615_ESTIMACION_INCENTIVOS.pdf",
        },
        {
          name: "Cifras de referencia",
          download: "20230704_CIFRAS_REFERENCIA.pdf",
        },
        {
          name: "Parte diario",
          download: "20230704_PARTE_DIARIO.pdf",
        },
        {
          name: "Informe de seguimiento de compras & KPIs",
          download: "20230704_SEGUIMIENTO_COMPRAS.pdf",
        },
      ],
    };
  },
  methods: {
    downloadFile(fileName) {
      const fileUrl = requirePDFs(`./${fileName}`);
      const link = document.createElement('a');
      link.href = fileUrl;
      console.log('fileUrl', fileUrl)
      link.download = fileName;
      link.target = '_blank';
      link.click();
    },
  }
};
</script>

<style lang="scss">
.table {
  font-family: inherit;
  font-size: inherit;
  tbody {
    tr {
      td:last-child {
        text-align: center;
      }
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
    
  }
}
</style>
